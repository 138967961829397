<template>
    <div>
        <v-toolbar dense class="tc-title">
            <v-toolbar-title>Student List</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>

            <v-row class='pl-4'>
                <v-col md="6">
                    <label>Academic Year: {{ay}}</label>
                </v-col>
                <v-col md="6">
                    <label>Placement Type: {{placementtype}}</label>
                </v-col>
            </v-row>
        </v-card>
        <br />
        <div>
            <v-row justify="end" class="mr-5">
                <v-btn v-if="learnerlist.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
            </v-row>
            <v-data-table  id="exceltable"  :headers="headers" :items="learnerlist"  class="elevation-1" :search="search" >
                <template v-slot:top class="red">
                    <v-toolbar flat color="white">
                        <v-toolbar-title>Stduent List</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                        <span style="width: 20px"></span>
                    </v-toolbar>
                </template>
            </v-data-table>
        </div>
        <v-overlay :value="overlay">
           <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import axios from "axios";
import {table2excel} from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
    data: () => ({
        overlay: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",

        learnerlist: [],
        ay: null,
        placementtype:null,

        instituteid:null,
        programtypeid:null,
        programid:null,
        ayid:null,
        placementtypeid:null,
        yearmasterid:null,

        headers: [ 
            {
                text: 'Sr.No.',
                align: 'left',
                sortable: true,
                value: 'srno',
            },
            {
                text: 'PRN/GR. No.',
                align: 'left',
                sortable: true,
                value: 'registration_number',
            },
            {
                text: 'Name',
                align: 'left',
                sortable: true,
                value: 'name',
            },
            {
                text: 'Email',
                align: 'left',
                sortable: true,
                value: 'email',
            },
             {
                text: 'Institute Email',
                align: 'left',
                sortable: true,
                value: 'inst_email',
            },
            {
                text: 'Mobile No.',
                align: 'left',
                sortable: true,
                value: 'mobile_no',
            },
            {
                text: 'Organization',
                align: 'left',
                sortable: true,
                value: 'organization',
            },
            {
                text: 'Program Type',
                align: 'left',
                sortable: true,
                value: 'programtype',
            },
            {
                text: 'Program',
                align: 'left',
                sortable: true,
                value: 'program',
            },
            {
                text: 'Year',
                align: 'left',
                sortable: true,
                value: 'year',
            },
            {
                text: 'Academic Year',
                align: 'left',
                sortable: true,
                value: 'academicyear',
            },
            {
                text: 'Placement Type',
                align: 'left',
                sortable: true,
                value: 'placementtype',
            },
        ],
    }),

    mounted() {
        this.instituteid = this.$route.params.orgid;
        this.programtypeid = this.$route.params.ptid;
        this.programid = this.$route.params.progid;
        this.ayid = this.$route.params.ayid;
        this.placementtypeid = this.$route.params.placementtypeid;
        this.yearmasterid = this.$route.params.yrid;

        const data = {
            instituteid:this.instituteid,
            programtypeid:this.programtypeid,
            programid:this.programid,
            ay:this.ayid,
            placementtype:this.placementtypeid,
            yearmasterid:this.yearmasterid,
        };
        axios
            .post("/TPO/getStudentListRegDashboard", data)
            .then(res => {
                if (res.data.msg == "200") {
                    this.learnerlist = res.data.learnerlist;
                    this.ay = res.data.ay;
                    this.placementtype = res.data.placementtype;
                } else {
                    console.log("error fetching data!");
                }
            });
    },

    methods: {
        exportexcel() {    
            $("#exceltable").table2excel({  
            name: "Worksheet Name",
            filename: "Student_reg_list", //do not include extension
            fileext: ".xls" // file extension
            });        
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    }
};
</script>
<style scoped>
    .required{
        color:red;
        font-size:14px;
        font-style:bold;
    }

    .tc-title {
        background-image: linear-gradient(-90deg, #fff, #057996);
        color: #fff;
        border-radius: 3px;
    }
</style>    